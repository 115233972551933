body {
	margin: 0;
	padding: 0;
}

#map {
	position: absolute;
	top: 0;
	bottom: 0;
	width: 100%;
}

.map-overlay {
	font: 12px/20px 'Helvetica Neue', Arial, Helvetica, sans-serif;
	position: absolute;
	width: 280px;
	bottom: 0;
	left: 0;
	padding: 5px;
}

.rounded-rect {
	background: white;
	border-radius: 10px;
	box-shadow: 0 0 50px -25px black;
}

.flex-center {
	position: absolute;
	display: flex;
	justify-content: center;
	align-items: center;
}

.flex-center.left {
	left: 0px;
}

.flex-center.right {
	right: 0px;
}

.sidebar-content {
	position: absolute;
	width: 95%;
	height: 95%;
	font-family: Arial, Helvetica, sans-serif;
	font-size: 32px;
	color: #80808088;
	background: #ffffffdd;
}

.sidebar-toggle {
	position: absolute;
	width: 1.3em;
	height: 1.3em;
	overflow: visible;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sidebar-toggle.left {
	right: -1.5em;
}

.sidebar-toggle.right {
	left: -1.5em;
}

.sidebar-toggle:hover {
	color: #0aa1cf;
	cursor: pointer;
}

.sidebar-button {
	position: absolute;
	width: 1.8em;
	height: 1.3em;
	overflow: visible;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sidebar-button.left {
	right: -2em;
	bottom: 0.4em;

}

.sidebar {
	transition: transform 1s;
	z-index: 1;
	width: 285px;
	height: 100%;
}


/*
The sidebar styling has them "expanded" by default, we use CSS transforms to push them offscreen
The toggleSidebar() function removes this class from the element in order to expand it.
*/
.left.collapsed {
	transform: translateX(-280px);
}

.right.collapsed {
	transform: translateX(280px);
}


@media screen and (max-width: 480px) {

	#map {
		position: absolute;
		height: 50%;
	}

	.map-overlay {
		position: relative;
		width: 100%;
		bottom: 0;
	}


	.sidebar-content {
		position: relative;
		width: 100%;
		height: 100%;
		background: #ffffff;
	}

	.sidebar-toggle {
		display: none;
	}

	.sidebar-button {
		position: relative;
		height: 80%;
		width: fit-content;
		left: 0;
	}

	.sidebar {
		width: 100%;
		height: 50%;
		bottom: 0;
	}


	/*
	The sidebar styling has them "expanded" by default, we use CSS transforms to push them offscreen
	The toggleSidebar() function removes this class from the element in order to expand it.
	*/
	.left.collapsed {
		transform: translateX(0);
	}
/* 
	.right.collapsed {
		transform: translateX(280px);
	} */

}